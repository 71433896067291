<template>
  <div :style="backgroundStyle" class="back">
    <v-card tile width="41.5%" height="100%" style="padding-top: 15%;">
      <v-form @submit.prevent>
        <v-card-text>
          <v-row>
            <v-col class="text-center">
              <img src="@/assets/images/logos/chess-hotel.png" alt="CHESS HOTEL" contain class="me-3 ">
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1"></v-col>
            <v-col>
              <v-text-field
                dense
                v-model="email"
                rounded
                outlined
                label="Email"
                placeholder="Digite seu email"
                hide-details="auto"
                class="mb-6 mt-2"
                :error-messages="message_error_email"
                @keyup="validEmail"
                :error="login_error"
              />
            </v-col>
            <v-col cols="1"></v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="1"></v-col>
            <v-col>
              <v-text-field
                dense
                rounded
                v-model="password"
                outlined
                :type="isPasswordVisible ? 'text' : 'password'"
                label="Senha"
                placeholder="Digite sua senha"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                hide-details="auto"
                class="mb-2 mt-2"
                @click:append="togglePasswordVisibility"
                :error-messages="message_error_password"
                :error="login_error"
                @keyup="validPassword"
              />
            </v-col>
            <v-col cols="1"></v-col>
          </v-row>
          <v-row>
            <v-col cols="1"></v-col>
            <v-col>
              <v-btn
                block
                rounded
                :loading="loading"
                color="primary"
                type="submit"
                class="mt-6"
                :disabled="loading"
                @click="send"
              >
                Entrar
              </v-btn>
            </v-col>
            <v-col cols="1"></v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <label @click="resetPassword">Esqueceu a senha?</label>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import {
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiFacebook,
  mdiGithub,
  mdiGoogle,
  mdiTwitter
} from '@mdi/js';
import themeConfig from '@themeConfig';
import { ref } from '@vue/composition-api';
import { mapActions, mapMutations } from 'vuex';

export default {
  setup() {
    const isPasswordVisible = ref(false);
    const email = ref('');
    const password = ref('');

    const socialLink = [
      { icon: mdiFacebook, color: '#4267b2', colorInDark: '#4267b2' },
      { icon: mdiTwitter, color: '#1da1f2', colorInDark: '#1da1f2' },
      { icon: mdiGithub, color: '#272727', colorInDark: '#fff' },
      { icon: mdiGoogle, color: '#db4437', colorInDark: '#db4437' }
    ];

    const images = [
      'https://images.pexels.com/photos/459402/pexels-photo-459402.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      'https://images.pexels.com/photos/2497926/pexels-photo-2497926.jpeg',
      'https://images.pexels.com/photos/1381420/pexels-photo-1381420.jpeg',
      'https://images.pexels.com/photos/3771110/pexels-photo-3771110.jpeg',
      'https://static.wixstatic.com/media/51a92e_286001d8d5114ed4b3a82411156ced6d~mv2.jpg/v1/fill/w_953,h_640,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/51a92e_286001d8d5114ed4b3a82411156ced6d~mv2.jpg'
    ];

    const backgroundStyle = ref({
      backgroundImage: `url(${images[Math.floor(Math.random() * images.length)]})`,
      backgroundSize: 'cover',
      width: '100%',
      height: '100%'
    });

    return {
      isPasswordVisible,
      email,
      password,
      socialLink,
      backgroundStyle,
      icons: { mdiEyeOutline, mdiEyeOffOutline },
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo
    };
  },
  data: () => ({
    loading: false,
    message_error_email: '',
    message_error_password: '',
    login_error: false
  }),
  methods: {
    ...mapActions('login', ['login']),
    ...mapMutations('login', ['setUser', 'setAuthorization']),
    send() {
      if (!this.validEmail() || !this.validPassword()) return;

      this.loading = true;
      const login = { email: this.email, password: this.password };

      this.login(login)
        .then(response => {
          this.setUser(response.data.user);
          this.setAuthorization(response.data.authorization);
          localStorage.setItem('accessToken', response.data.authorization.token);
          localStorage.setItem('permissions', JSON.stringify(response.data.user.role.permission));
          this.$router.push('/dashboard');
        })
        .catch(error => {
          const status = error.response.status;
          if (status === 500) {
            this.$toast.error('Erro no servidor');
          } else {
            this.$toast.error('Login não autorizado');
            this.login_error = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validEmail() {
      if (this.email === '') {
        this.message_error_email = 'Obrigatório';
        return false;
      }
      this.message_error_email = '';
      return true;
    },
    validPassword() {
      if (this.password === '') {
        this.message_error_password = 'Obrigatório';
        return false;
      }
      this.message_error_password = '';
      return true;
    },
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    resetPassword() {
      // Logic for password reset
    }
  }
};
</script>

<style lang="scss" scoped>
.back {
  background-size: cover;
  width: 100%;
  height: 100%;
}

.logo {
  width: 60px;
  height: 60px;
  float: left;
}

.container-logo {
  width: 296px;
  height: 57px;
  margin-bottom: 40px;
}

.card {
  width: 376px;
  height: 479px;
  box-sizing: border-box;
  padding: 20px;
}

.capcha {
  width: 296px;
  height: 106px;
  margin-bottom: 47px;
  margin-top: 25px;
  background: rgba(127, 127, 134, 0.062);
}

.name_app {
  font-size: 20px;
}

hr {
  height: 3px;
  background: #f7f7f7;
  border: none;
  margin-top: 25px;
  margin-bottom: 20px;
}
</style>
